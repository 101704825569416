import React from 'react';
import Layout from '@components/layout/layout';
import { useStaticQuery, graphql } from 'gatsby';
import Seo from '@components/seo/seo';
import '../styles/default-page.sass';

export interface IRegulationsProps {}

const Regulations: React.FC<IRegulationsProps> = () => {
  const { wpPage } = useStaticQuery(graphql`
    query Regulations {
      wpPage(slug: { eq: "regulamin" }) {
        seo {
          metaDesc
          title
          focuskw
        }
        content
      }
    }
  `);
  return (
    <Layout>
      <Seo
        title={wpPage.seo.title}
        description={wpPage.seo.metaDesc}
        keywords={wpPage.seo.focuskw}
        pathname="/regulamin/"
      />
      <div className="bg-blueLight">
        <div className="container mx-auto max-w-5xl px-4 py-6 lg:py-20">
          <h1 className="text-center text-2xl font-semibold lg:text-4xl">
            Regulamin
          </h1>
          <div
            className="article-content"
            dangerouslySetInnerHTML={{ __html: wpPage.content }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default Regulations;
